<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    window.close();
  },
};
</script>
<style lang="less"></style>
